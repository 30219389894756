.main-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height: 100vh;
  background-color: #282c34;
  color: white;

  .row-card {
    cursor: pointer;
    margin: 10px;
    padding: 15px;
    font-size: calc(10px + 2vmin);
    min-width: 80vw;
    box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.75);
    border-radius: 5px;
  }

  .top-toolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;
    width: 100%;
    height: 40px;
    background-color: rgb(134, 134, 134);
    position: fixed;
    top: 0;

    button {
      cursor: pointer;
      background-color: #fff;
      height: 30px;
      border: none;
      border-radius: 15px;
    }
  }
}

.bricks-wrapper {
  width: 90%;
  height: 90%;
  padding: 10px;

  .brick {
    min-width: 220px;
    min-height: 280px;
    border-radius: 5px;
    box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.75);
    padding: 10px;

    label {
      font-weight: 600;
      padding: 10px;
    }

    .brick-actions {
      padding: 5px;
      display: flex;
      justify-content: space-evenly;

      span {
        cursor: pointer;
        padding: 5px;
        // height: 30px;
        border-radius: 12px;
        background-color: #fff;
        color:rgb(134, 134, 134);
      }
    }
  }
}